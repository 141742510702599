"use strict";

//
// Форматирование кредитных карт
//

export let creditCardInputFormatter = function (e) {
    if (e.target.value === e.target.lastValue) return;
    let caretPosition = e.target.selectionStart
    const sanitizedValue = e.target.value.replace(/[^0-9]/gi, '')
    const parts = []

    const len = sanitizedValue.length
    for (let i = 0; i < len; i += 4) {
        parts.push(sanitizedValue.substring(i, i + 4));
    }

    for (let i = caretPosition - 1; i >= 0; i--) {
        const c = e.target.value[i];
        if (c < '0' || c > '9') {
            caretPosition--;
        }
    }
    caretPosition += Math.floor(caretPosition / 4);

    e.target.value = e.target.lastValue = parts.join(' ');
    e.target.selectionStart = e.target.selectionEnd = caretPosition;
    const limit = parseInt(e.target.dataset.limit)
    if (limit) {
        e.target.value = e.target.value.substring(0, limit)
    }
}


export let creditCardExpiresInputFormatter = function (e) {
    if (e.target.value === e.target.lastValue) return;
    let caretPosition = e.target.selectionStart;
    const sanitizedValue = e.target.value.replace(/[^0-9]/gi, '');
    let parts = []
    const len = sanitizedValue.length
    for (let i = 0; i < len; i += 2) {
        parts.push(sanitizedValue.substring(i, i + 2));
    }
    for (let i = caretPosition - 1; i >= 0; i--) {
        const c = e.target.value[i];
        if (c < '0' || c > '9') {
            caretPosition--;
        }
    }
    caretPosition += Math.floor(caretPosition / 2);
    e.target.value = e.target.lastValue = parts.join('/');
    e.target.selectionStart = e.target.selectionEnd = caretPosition;

    const limit = parseInt(e.target.dataset.limit)
    if (limit) {
        e.target.value = e.target.value.substring(0, limit)
    }
}

//
// Форматирование числовых значений
//

export function numberFormat(value, digitsLimit = -1) {
    let v = value.replace(',', '.')
    let parts = v.split('.')
    let digits = ''
    let integer = ''
    if (parts.length > 0) {
        integer = parts[0]
    }
    if (parts.length > 1) {
        digits = parts[1]
    }

    let left = integer ? integer.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ") : ''
    let right = digits && digitsLimit !== 0 ? ('' + digits.replace(/\D/g, "")) : ''
    let sep = digitsLimit !== 0 && (parts.length > 1 || right) ? '.' : ''
    if (digitsLimit > 0) {
        right = right.substring(0, digitsLimit)
    }

    return left ? left + sep + right : ''
}

//
// Получаем информацию из Cookie
//

export function getCookie(cName) {
    const name = cName + "="
    const cDecoded = decodeURIComponent(document.cookie) //to be careful
    const cArr = cDecoded.split('; ')
    let res
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length)
    })
    return res
}

//
// Устанавливаем значение в Cookie
//

export function setCookie(cName, cValue, expDays = 30) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}
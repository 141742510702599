"use strict";
import 'jquery/dist/jquery.min'
import 'core/popper.min'
import 'perfect-scrollbar'
import 'smooth-scrollbar'
import * as bootstrap from 'bootstrap'

import {getCookie, setCookie} from './utils'

window.$ = $

//
// Системное обновление
//

// Обновляем информацию о текущем часовом поясе пользователя
let currentTimeZone = getCookie('timezone')
if (!currentTimeZone) {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    setCookie('timezone', timezone)
}

//
// Блок обновления интерфейса
//

// initialization of Tooltips
const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
})

// initialization of Popovers
const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
popoverTriggerList.map(function(popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
})

document.addEventListener("DOMContentLoaded", function() {
    const toastElList = [].slice.call(document.querySelectorAll(".toast"));

    const toastList = toastElList.map(function(toastEl) {
        return new bootstrap.Toast(toastEl);
    });

    const toastButtonList = [].slice.call(document.querySelectorAll(".toast-btn"));

    toastButtonList.map(function(toastButtonEl) {
        toastButtonEl.addEventListener("click", function() {
            const toastToTrigger = document.getElementById(toastButtonEl.dataset.target);

            if (toastToTrigger) {
                const toast = bootstrap.Toast.getInstance(toastToTrigger);
                toast.show();
            }
        });
    });
});